import { Query, SanityBlockContent, SanitySeo } from "@graphql-types";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { toPlainText } from "@util/helper";

interface Props {
  seoData?: SanitySeo | null;
  post?: boolean;
  overwriteTitle?: string;
  overwriteImage?: string;
  overwriteDescription?: string;
  slug?: string;
}

const SEO = (props: Props) => {
  const { sanitySiteSeo }: Query = useStaticQuery(graphql`
    query defaultSEOQuery {
      sanitySiteSeo {
        defaultSEO {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  var urlString = process.env.GATSBY_BASE_URL;

  if (props.slug) {
    urlString = urlString + "/" + props.slug;
  }

  const overwriteDescription =
    props.overwriteDescription?._rawBlocks != null
      ? toPlainText(props.overwriteDescription as SanityBlockContent)
      : props.overwriteDescription;

  const title =
    props.overwriteTitle ||
    props.seoData?.pageTitle ||
    sanitySiteSeo?.defaultSEO?.pageTitle ||
    "Everyday by Barker's";
  const description =
    props.seoData?.pageDescription ||
    overwriteDescription ||
    sanitySiteSeo?.defaultSEO?.pageDescription ||
    "Everyday by Barker's";
  const image =
    props.seoData?.ogImage?.asset?.url ||
    props.overwriteImage ||
    sanitySiteSeo?.defaultSEO?.ogImage?.asset?.url ||
    "";
  const keyWords =
    props.seoData?.pageKeyWords ||
    sanitySiteSeo?.defaultSEO?.pageKeyWords ||
    "Everyday by Barker's";

  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta name="keywords" content={keyWords} />
      <html lang="en" />

      <meta property="og:title" content={title} />
      <meta property="og:type" content={props.post ? `article` : `website`} />
      <meta property="og:url" content={urlString} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      <meta property="twitter:site" content={urlString} />
      <meta name="twitter:description" content={description} />
      {/* <Script strategy="off-main-thread">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GTMTRACKINGCODE}');`}
      </Script> */}
      {/* 
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_MEASUREMENT_ID}`}
        strategy="off-main-thread"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="off-main-thread"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${process.env.GATSBY_GA_MEASUREMENT_ID}')`,
        }}
      /> */}
    </Helmet>
  );
};

export default SEO;
